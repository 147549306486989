<template>
  <b-card no-body>
    <b-table
        id="refListTable"
        ref="refListTable"
        no-provider-paging
        :fields="tableColumns"
        :items="itemLists"
        :per-page="perPage"
        responsive
    >
      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>
      <!-- code  -->
      <template #cell(code)="{item}">
        <b-link
          :to="{ name: 'apps-transactions-purchase-order-detail', params: { id: item.id }}"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
            #{{ item.code }}
          </span>
        </b-link>
      </template>
      <template #cell(image)="{item}">
        <b-link
          @click="newTab(item.image)"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
            {{ item.image }}
          </span>
        </b-link>
      </template>

    </b-table>
    <b-button
        class="mb-sm-10 mb-md-0"
        :to="{ name: 'apps-ap-cash-advance-list' }"
    >
    <feather-icon
      icon="ArrowLeftIcon"
    />
    {{ $t('globalActions.backToList') }}
    </b-button>
 </b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BLink,
    BButton
} from 'bootstrap-vue'
import useListTable from '@/comp-functions/useListTable'
import { ref ,onMounted } from '@vue/composition-api'
import useFormResource from '@/comp-functions/useFormResource'

export default {
  components: {
    BCard,
    BTable,
    BLink,
    BButton
  },
  setup () {
    const {
        paramsId,
        show
    } = useFormResource({ url: 'transaction/purchase/payment', 
                          localeContextPath: 'apps.transactions.purchase.invoice.singular.invoice',
                          redirectPathName: 'apps-transactions-purchase-order' })
    const itemLists = ref({})
     // Table atas
    const tableColumns = [
      { key: 'code', label: '#' },
      { key: 'image', label: 'Image' }
    ]
    onMounted(() => {
        getDataPreview()
    })

    const getDataPreview = async () => {
      const data = await show()
      let dataTable = [];
      for (let i = 0; i < data.name_file.length; i++) {
            dataTable.push({
                code: data.code,
                image: data.name_file[i],
                id: data.id
            })
      }
      itemLists.value = dataTable
      console.log(itemLists.value)

    }

    const newTab = async (image) => {
        var link = process.env.VUE_APP_BASE_API_URL
        link = link.replace('/api','');
        window.open(link + 'assets/images/payment/'+ image)
    }
 
    return {
        tableColumns,
         ...useListTable({
        url: `transaction/purchase/payment/`+ paramsId
      }),
      paramsId,
      itemLists,
      newTab
    }
  }
}
</script>